import { AiFillFacebook, AiOutlineGoogle, AiFillInstagram } from 'react-icons/ai';


export default function Footer() {
  return (
    <div className="grid grid-cols-2 py-12 mx-4 mt-4 mb-2 shadow-lg rounded-xl bg-gradient-to-t from-yellow-600 to-yellow-500 px-14">
      <div>
        <p className="text-4xl font-bold text-white font-Play">POSH</p>
        <p className="text-sm text-stone-200">100 Tradecenter Drive, Woburn, MA 01801</p>
        <p className="text-sm text-stone-200">+1 (413) 210-8346</p>
        <p className="text-sm text-stone-200">Copyright © 2022 All Rights Reserved</p>
      </div>
      <div className="flex items-end justify-end space-x-4">
        <a href="https://www.instagram.com/poshcars.io/" target="_blank">
          <AiFillInstagram className="text-2xl text-white" />
        </a>
        <a href="https://www.facebook.com/p/POSH-Cars-61551725499679/" target="_blank">
          <AiFillFacebook className="text-2xl text-white" />
        </a>
        <a href="https://maps.app.goo.gl/KvpibgQLq4hCGY5o8" target="_blank">
          <AiOutlineGoogle className="text-2xl text-white" />
        </a>
      </div>
    </div>
  );
}
