import { useState, useContext, useEffect } from "react";
import DataContext from "../../context/data-context";
import VehicleCard from "./VehicleCard";
import SearchBar from "../UI/SearchBar";
import Heading from "../UI/Heading";
import { useNavigate } from "react-router-dom";
import { MdRefresh } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { useLocation } from "react-router-dom";

export default function Vehicles() {
  const { fetchData, vehicles } = useContext(DataContext);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const searchParam = params.get("search") ?? "";
  const navigate = useNavigate();

  // Initialize States
  const [searchValue, setSearchValue] = useState(searchParam);
  const [filteredVehicles, setFilteredVehicles] = useState([]);

  // Search handler
  const searchHandler = (value) => {
    navigate(
      `/vehicles?search=${value}`,
      {},
      { replace: true }
    );
    setSearchValue(value);
  };

  /* ======================= SEARCH BOOKINGS ======================= */
  const searchVehicles = (records) => {
    return records.filter((vehicle) => {
      const string = `${vehicle.model} ${vehicle.make} ${vehicle.year}
    ${vehicle.license} ${vehicle.license_state} ${vehicle.partner.name} 
    ${vehicle.status} ${vehicle.vin}`.toLowerCase();
      return string.includes(searchValue.toLowerCase());
    });
  };
  // Clear Search Filter
  const clearSearch = () => {
    setSearchValue("");
    navigate(`/vehicles`, {}, { replace: true });
  };

  /* ======================= APPLY FILTER BOOKINGS ======================= */
  const applyFilters = () => {
    let records = [...vehicles];

    //Apply search filter
    if (searchValue) {
      records = searchVehicles(records);
    }
    setFilteredVehicles([...(records ?? [])]);
  };

  // Fetch Bookings on load
  useEffect(() => {
    applyFilters();
  }, [searchValue]);

  return (
    <div className="grid gap-4 overflow-hidden bg">
      <Heading
        title="Vehicles"
        description="The following list shows all vehicles added to the Partner's program. To add a new vehicle, click the '+' icon."
      >
        <AiOutlinePlus
          onClick={() => navigate("/vehicles/add")}
          className="icon"
        />

        <MdRefresh onClick={() => fetchData()} className="icon" />
      </Heading>
      <SearchBar searchHandler={searchHandler} clearSearch={clearSearch}
        searchValue={searchValue} />
      <div className="max-h-[600px] overflow-scroll ">
        <div className="grid gap-4">
          {filteredVehicles.map((vehicle, idx) => (
            <VehicleCard key={idx} vehicle={vehicle} />
          ))}
        </div>
      </div>
    </div>
  );
}
