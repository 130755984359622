import { useRef, useEffect } from "react";

export default function SearchBar({ searchHandler, clearSearch, searchValue }) {
  const searchRef = useRef(null);

  useEffect(() => {
    if (searchValue) searchRef.current.value = searchValue ?? "";
  }, [searchValue]);

  const submitHandler = (e) => {
    e.preventDefault();
    searchHandler(searchRef.current.value.toLowerCase());
  };

  const clearHandler = () => {
    searchRef.current.value = "";
    clearSearch();
  };

  return (
    <form
      onSubmit={submitHandler}
      className="flex items-center space-x-2 md:space-x-4"
    >
      <input
        name="search"
        ref={searchRef}
        className="w-full text-sm border rounded-lg border-primary input"
        type="text"
        htmlFor="search"
        title="search"
      />

      <button type="submit" className="w-32 text-sm btn-primary">
        Search
      </button>
      <div
        onClick={() => clearHandler()}
        className="w-32 text-sm btn-secondary"
      >
        Clear
      </div>
    </form>
  );
}
